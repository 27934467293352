const config = {
  env: 'local',

 // apiUrl: "http://localhost:5515/admin/",
   //apiUrl: " http://148.66.159.65:5562/admin/",
  apiUrl: "https://api.sathivakil.com/admin/",
  //  shipRocketApiUrl: "https://apiv2.shiprocket.in/v1/external/"

}

export default config;
